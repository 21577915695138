const projects = [
    {
      id: 1,
      title: "Portfolio AB",
      description: "Réalisation d'un portfolio Artiste 3D",
      image: "./imgprojets/homepage-ab.png",
      link: "https://satisfaction.netlify.app/",
    },
    {
        id: 2,
        title: "Site entreprise Nanaba",
        description: "Réalisation d'un site web pour l'entreprise Nanaba",
        image: "./imgprojets/homepage-nanaba.png",
        link: "https://nanaba.tech",
      },
      {
        id: 3,
        title: "Encyclopédie Rick & Morty",
        description: "Réalisation d'une encyclopédie sur l'univers de Rick & Morty",
        image: "./imgprojets/homepage-rickmorty.png",
        link: "https://rickandmortylittleapi.netlify.app/",
      },

      {
        id: 4,
        title: "Portfolio Artiste 3D / Motion designer",
        description: "Réalisation d'un portfolio Artiste 3D / Motion designer",
        image: "./imgprojets/homepage-gb.png",
        // link: "https://resplendent-raindrop-7b88b9.netlify.app",
      },

      {
        id: 5,
        title: "Site d'idées de cocktails",
        description: "Réalisation d'un projet de site web d'idées de cocktails",
        image: "./imgprojets/homepage-cocktails.png",
        link: "https://cocktailid.netlify.app",
      },

      {
        id: 6,
        title: "To do list / debut clone window xp",
        description: "Réalisation d'un projet de to do list js & debut d'unn clone de window xp",
        image: "./imgprojets/todolistxp.png",
        link: "https://todolistxp.netlify.app",
      },
      {
        id: 7,
        title: "Hommage a Nepal",
        description: "Réalisation d'un projet de site web een hommage a Nepal et ses créations",
        image: "./imgprojets/pnepal.png",
        link: "https://nepalrap.netlify.app",
      },
      

     
    // Ajoutez d'autres projets
  ];
  
  export default projects;