import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./roots.css";
// import { useDarkMode } from "../components/darkModeProvider";

export default function Roots() {
  const location = useLocation();
  // const { isDarkMode, toggleDarkMode } = useDarkMode();

  // State pour suivre la page actuelle
  const [currentPage, setCurrentPage] = useState("home");

  // Mettez à jour la page actuelle chaque fois que l'emplacement (location) change
  React.useEffect(() => {
    // Extraire le nom de la page à partir de l'emplacement (location.pathname)
    const pageName = location.pathname.replace("/", "") || "home";
    setCurrentPage(pageName);
  }, [location]);

  return (
    <>
      <header>
        {/* <nav className={`neumorphic ${isDarkMode ? "dark-mode" : ""}`}> */}
          <nav className={`neumorphic`}>
          <Link to="/" className={currentPage === "home" ? "activeLink links" : "links"}>
            Home
          </Link>
          <Link
            to="/projects"
            className={currentPage === "projects" ? "activeLink links activeLink-move" : "links"}
          >
            Projects
          </Link>
          <Link
            to="/about"
            className={currentPage === "about" ? "activeLink links activeLink-move" : "links"}
          >
            About
          </Link>
          
        </nav>
        {/* <button onClick={toggleDarkMode}>
            {isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
          </button> */}
      </header>
      <Outlet />
    </>
  );
}