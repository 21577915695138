import React from "react";
import "./cards.css";

const Cards = ({ project }) => {
    
    return (
        <div className="cardp">
            <div className="cardp-content"></div>
        <div className="cardp-image">
            <img src={project.image} alt={project.title} />
        </div>
        <div className="cardp-infos">
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer" className="linkp">
            Découvrir le projet
            </a>
        </div>
        </div>
    );
}
export default Cards;
