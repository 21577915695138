import React from "react";
import Cards from '../cards/cards.jsx';
import "./list.css";

const List = ({ projects }) => { 

    return ( 
        <>
            {/* <div className="projects-list"> */}
                {projects.map((project) => (
                    <Cards project={project} key={project.id} />
                ))}
            {/* </div> */}
        </>
    )
}
export default List;