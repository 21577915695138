import React from "react";
import "./about.css";

export default function About() {
  return (
    <>
      <div className="about">
        <div className="container3">
          <div className="about-card1">
            <div className="aboutcard1-content">
              <h3>Hey, c'est Franck</h3>
              <p>
                Je suis un jeune développeur passionné par la création et motivé
                par l'opportunité de réaliser des projets innovants dans le
                domaine de la programmation. Je suis toujours prêt à relever de
                nouveaux défis. Ma volonté de créer des solutions novatrices me
                pousse à explorer de nouvelles idées. Je suis ouvert aux
                opportunités de collaboration et prêt à apporter ma contribution
                à des projets intéressants et enrichissants.
              </p>
            </div>
          </div>

          <div className="about-card2">
            <div className="aboutcard2-content">
              <img
                src="./cest-moi.jpg"
                alt=""
              />
            </div>
          </div>

          <div className="about-card3">
            <div className="aboutcard3-content">
              <h3>
                Mes Disponibilité
              </h3>
              <p>
                Je reste disponible pour toute opportunité de collaboration ou de travail. N'hésitez pas à me contacter pour plus d'informations.
              </p>

              <div className="contact-links">
                
                <a
                  href="https://www.linkedin.com/in/franck-tolosano/"
                  target="blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-linkedin"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                    <path d="M8 11l0 5" />
                    <path d="M8 8l0 .01" />
                    <path d="M12 16l0 -5" />
                    <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                  </svg>
                </a>
                <a href="https://github.com/ToFranck" target="blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-github"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container4">
          <div className="about-card4">
            <div className="aboutcard4-content">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d92308.56873323896!2d7.277606936352717!3d43.69718950621492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cdd0106a852d31%3A0x40819a5fd979a70!2sNice!5e0!3m2!1sfr!2sfr!4v1702153178040!5m2!1sfr!2sfr"></iframe>
            </div>
          </div>

          <div className="about-card5">
            <div className="aboutcard5-content">
              <h3>Hobbies</h3>
              <ul>
                <li>🏀 Basketteballs</li>
                <li>🎮 Jeux videos</li>
                <li>🍜 Cuisine</li>
                <li>🎵 Musique</li>
                <li>🎨 Art</li>
              </ul>
            </div>
          </div>
          <div className="about-card6">
            <div className="aboutcard6-content">
              <h3>Experience</h3>
              <div className="part-exp">
                
                <div className="exp">
                  <p className="name">Nanaba</p>
                  <div className="line"></div>
                  <p className="post">Developpeur web</p>
                  <p className="year">2021 - 2023 </p>
                </div>
                <div className="exp">
                  <p className="name">Media Pole</p>
                  <div className="line"></div>
                  <p className="post">Developpeur web</p>
                  <p className="year">2021 - 2021 </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
