import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Roots from './roots/Roots.jsx';
import Home from './pages/home/home.jsx';
import About from './pages/about/about.jsx';
import Projects from './pages/projects/projects.jsx';




const router = createBrowserRouter ([ 
  {
    element: <Roots />,
    children: [
      {path: '/', element: <Home />},
      {path: '/about', element: <About />},
      {path: '/projects', element: <Projects />},
    
    ]

  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <DarkModeProvider> */}
    <RouterProvider router={router} />
    {/* </DarkModeProvider> */}

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
