import React from "react";
import "./home.css";
// import { useDarkMode } from "../../components/darkModeProvider";

export default function Home() {
  // const { isDarkMode } = useDarkMode();
  return (
    <>
      {/* <div className={isDarkMode ? "dark-mode" : ""}> */}
      <div className="home">
        <div className="container1">
          <div className="long-card1">
            <div className="longcard1-content">
              <h1>Hey</h1>
              <p>
                 Moi c'est Franck, je suis un jeune développeur passionné par la création et motivé par l'opportunité de réaliser des projets innovants dans le domaine de la programmation. Je suis toujours prêt à relever de nouveaux défis. Ma volonté de créer des solutions novatrices me pousse à explorer de nouvelles idées. Je suis ouvert aux opportunités de collaboration et prêt à apporter ma contribution à des projets intéressants et enrichissants.
              </p>
            </div>
          </div>

          <div className="small-card1">
            <div className="smallcard1-content">
              <div className="part1">
                <img src="./illustrations/image.png" alt="" />
              </div>
              <h3>Mes Projets</h3>
              <div className="part2">
                <p>En voir plus</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-chevron-right"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </div>
            </div>
          </div>
          <div className="small-card1">
            <div className="smallcard1-content">
              <div className="part1">
                <img src="./illustrations/discuter.png" alt="" />
              </div>
              <h3>A propos</h3>
              <div className="part2">
                <p>En voir plus</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-chevron-right"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="container2">
          <div className="small-card2">
            <div className="smallcard2-content">
              <h3>Disponible pour travailler</h3>
              <p> N'hésitez pas à me contacter pour plus d'informations.</p>
              
                {/* <p className="email">tolosanofranck@hotmail.com</p> */}
                <div className="contact-links">
                  
                  <a href="https://www.linkedin.com/in/franck-tolosano/" target="blank"> 
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-linkedin" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M8 11l0 5" /><path d="M8 8l0 .01" /><path d="M12 16l0 -5" /><path d="M16 16v-3a2 2 0 0 0 -4 0" /></svg>
                  </a>
                  <a href="https://github.com/ToFranck" target="blank">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-github" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" /></svg>

                  </a>
                
               </div>
            </div>
          </div>

          <div className="small-card3">
            <div className="smallcard3-content">
              <video
                className="video"
                autoPlay
                loop
                muted
                playsInline>
                  <source src="/abstract.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="long-card2">
            <div className="longcard2-content">
              <h3>Skills </h3>
              <div className="longcard-part2">
              <div className="cont-skills">
                <p>HTML / CSS / Javascript / React / Wordpress / Postman / Git / Firebase / Mongodb / Figma</p>
                
              </div>
              <div className="longcard2-img">
              <img src="./illustrations/passe.png" alt="" />
              </div>
              </div>
            </div>
          </div>
        </div>

        {/* </div> */}
      </div>
    </>
  );
}
